// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://a8fa08703145430f8d8ab3ac2bb18925@o1223220.ingest.sentry.io/6374818',
  environment: process.env.SENTRY_ENV,
  release: process.env.BITBUCKET_COMMIT,
  ignoreErrors: ["TypeError: Cannot read properties of undefined (reading 'x')"],
  enabled: process.env.BITBUCKET_COMMIT !== undefined,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  sampleRate: Number(process.env.SENTRY_SAMPLE_RATE_PHOTO_ALBUM) || 0.01,
  tracesSampleRate: Number(process.env.SENTRY_TRACE_SAMPLE_RATE_PHOTO_ALBUM) || 0.001,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1,
});
